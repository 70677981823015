import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {//首页公共部分
        path: '/',
        component: () => import('../views/index/Index.vue'),
        children: [
            {//首页
                path: '/home',
                alias: '/',
                component: () => import('../views/index/Welcom.vue')
            },
            {//公司简介
                path: '/comp',
                component: () => import('../views/delit/Company.vue')
            },
            {//产品简介
                path: '/product',
                component: () => import('../views/delit/Product.vue')
            },
            {//产品特色
                path: '/toge',
                component: () => import('../views/delit/Toge.vue')
            },
            {//合作企业
                path: '/unique',
                component: () => import('../views/delit/Unique.vue')
            },
            {//联系我们
                path: '/relat',
                component: () => import('../views/delit/Relation.vue')
            },
        ]
    },

]

const router = new VueRouter({
    routes
})

export default router
